import httpClient from '../httpClient';

const getKnowledgeBaseItems = (licenseId, moduleId = null) => {
  let params = {};
  if (moduleId) {
    params['filter[module]'] = moduleId;
  }
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/licenses/${licenseId}/knowledge-base`, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getKnowledgeBaseItem = (licenseId, itemId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/licenses/${licenseId}/knowledge-base/${itemId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getKnowledgeBaseItemMarkdown = (licenseId, itemId, markdownId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(
        `/licenses/${licenseId}/knowledge-base/${itemId}/markdown/${markdownId}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getKnowledgeBaseItems,
  getKnowledgeBaseItem,
  getKnowledgeBaseItemMarkdown,
};
