<script>
import {
  getKnowledgeBaseItem,
  getKnowledgeBaseItemMarkdown,
  getKnowledgeBaseItems,
} from '@/services/api/knowledge-base.api';
import { mapGetters } from 'vuex';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import AntLoading from '@/components/AntLoading.vue';

export default {
  name: 'KnowledgeBase',
  components: { AntLoading, PanelResizable },
  data: () => {
    return {
      items: [],
      selectedItem: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['selectedLicense']),
  },
  async mounted() {
    this.isLoading = true;
    this.items = await getKnowledgeBaseItems(
      this.selectedLicense.id,
      this.$route.query.module
    );
    if (this.$route.params.itemId) {
      this.selectedItem = this.items.findIndex(
        (item) => item.id === this.$route.params.itemId
      );
    }
    this.isLoading = false;
  },
};
</script>

<template>
  <div class="d-flex">
    <panel-resizable side="left" :min-width="25" :default-width="400">
      <v-list v-if="items.length > 0" nav class="bg-transparent">
        <v-subheader>Items</v-subheader>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="item in items"
            :key="item.id"
            @click="
              $router.push({
                name: 'KnowledgeBaseItem',
                params: { itemId: item.id },
              })
            "
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div
        v-else-if="isLoading"
        class="flex-1 d-flex align-center justify-center"
      >
        <ant-loading />
      </div>
      <div v-else class="flex-1 d-flex align-center justify-center">
        No items found within this license
      </div>
    </panel-resizable>
    <router-view v-if="selectedItem !== null" class="flex-1" />
    <div v-else class="flex-1 d-flex align-center justify-center font-italic">
      Please select an item on the left
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
